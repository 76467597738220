import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { editMenuRawSetting, getDeviceMenu, invokeDeviceEvent } from './actions';

import DeviceMenuHelpModal from './DeviceMenuHelpModal';
import DeviceMenuItem from './components/DeviceMenuItem/DeviceMenuItem';

import './device-menu.scss';

const notificationTimeoutMilliseconds = 3000;

const canShowMenuRows = menu => {
  // Magic menu ids that're allowed to show were copied from the mobile app.
  return (
    menu.menuType === 'MENU_TYPE_LIST' ||
    (menu.menuType === 'MENU_TYPE_CUSTOM' &&
      (menu.menuTypeId == 60011 ||
        menu.menuTypeId == 60012 ||
        menu.menuTypeId == 60016 ||
        menu.menuTypeId == 60017 ||
        menu.menuTypeId == 60008 ||
        menu.menuTypeId == 60032 ||
        menu.menuTypeId == 60033 ||
        menu.menuTypeId == 60034))
  );
};

const DeviceMenu = ({
  device,
  deviceMenu,
  getDeviceMenu,
  invokeDeviceEvent,
  editMenuRawSetting,
  isManageEnabled,
  userInfo,
  premiumFeatureType,
}) => {
  const [menuLevelsHistory, setMenuLevelsHistory] = useState([0]);
  const [menuLoading, setMenuLoading] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [loadingDeviceSubMenu, setLoadingDeviceSubMenu] = useState(false);

  const timeout = useRef(null);
  const refreshIntervalCycle = useRef(null);

  const handleDeviceMenuUpdate = async (deviceId, id) => {
    const isSuccessful = await getDeviceMenu(deviceId, id);

    if (isSuccessful) {
      setCurrentMenuId(id);
    }
  };

  const executeRefreshCycle = async (deviceId, level) => {
    if (!deviceMenu.updateSettingsProgressCounter) {
      await handleDeviceMenuUpdate(deviceId, level);
    }
  };

  const startRefreshIntervalCycle = () => {
    const [level] = menuLevelsHistory;

    if (refreshIntervalCycle.current) {
      clearInterval(refreshIntervalCycle.current);
    }

    refreshIntervalCycle.current = setInterval(() => {
      executeRefreshCycle(device.id, level);
    }, 10 * 1000);
  };

  const disposeRefreshIntervalCycle = isSettable => {
    if (!refreshIntervalCycle.current) return;
    clearInterval(refreshIntervalCycle.current);
    refreshIntervalCycle.current = null;

    if (isSettable) {
      setMenuLevelsHistory([]);
    }
  };

  useEffect(() => {
    const loadMenu = async () => {
      setMenuLoading(true);

      try {
        const [level] = menuLevelsHistory;
        if (level === 0) {
          setMenuLoading(false);
          return await handleDeviceMenuUpdate(device.id, 0);
        }

        startRefreshIntervalCycle();
      } finally {
        setMenuLoading(false);
      }
    };

    loadMenu();

    return () => {
      disposeRefreshIntervalCycle(false);
      clearTimeout(timeout.current);
    };
  }, [menuLevelsHistory]);

  const followLink = async link => {
    setLoadingDeviceSubMenu(true);
    await handleDeviceMenuUpdate(device.id, link.id);
    setMenuLevelsHistory(prevValue => {
      return [link.id, ...prevValue];
    });
    setLoadingDeviceSubMenu(false);
  };

  const processEvent = async menuItem => {
    await invokeDeviceEvent(device.id, menuItem.eventId);
    await handleDeviceMenuUpdate(device.id, menuLevelsHistory[0]);
  };

  const changeSetting = async (menuItem, value, needsRefresh = false, showSpinner = true) => {
    const unit = menuItem.metadata && menuItem.metadata.unit;

    if (needsRefresh && showSpinner) {
      setMenuLoading(true);
    }

    try {
      disposeRefreshIntervalCycle();
      await editMenuRawSetting(device.id, currentMenuId, menuItem.parameterId, value, unit);
    } catch (error) {
      if (error.response.status == 403 || error.response.status == 401) {
        NotificationManager.error(
          <FormattedMessage
            id="devices.noPermissionToChangeSettings"
            defaultMessage="You are not authorized to change device settings"
          />,
          <FormattedMessage id="security.Error" defaultMessage="Error" />,
          notificationTimeoutMilliseconds,
        );
        return;
      }

      if (error.response.status == 400) {
        NotificationManager.error(
          <FormattedMessage id="devices.failedToChangeSettings" defaultMessage="Failed to change device settings" />,
          <FormattedMessage id="security.Error" defaultMessage="Error" />,
          notificationTimeoutMilliseconds,
        );
        return;
      }

      throw error;
    } finally {
      startRefreshIntervalCycle();
    }

    if (needsRefresh) {
      setTimeout(async () => {
        if (await handleDeviceMenuUpdate(device.id, menuLevelsHistory[0])) {
          setMenuLoading(false);
        }
      }, 2000);
    }
  };

  const back = () => {
    setMenuLevelsHistory(menuLevelsHistory.filter((_, i) => i !== 0));
  };

  const [menuLevel] = menuLevelsHistory;
  const menu = deviceMenu[device.id] ? deviceMenu[device.id][menuLevel] : null;

  if (!device.online) {
    return (
      <div className="menu-unavailable-wrapper">
        <FormattedMessage
          id="devices.menu-simplified-unavailable"
          defaultMessage="Could not connect to device. Some functionality may not be available."
        />
      </div>
    );
  }

  if (!menu || menuLoading) {
    return (
      <div className="menu-spinner-wrapper">
        <Spinner dark />
      </div>
    );
  }

  if (!canShowMenuRows(menu)) {
    return (
      <div>
        {menuLevelsHistory.length > 1 && (
          <Button className="button--default button--bottom-spacing" onClick={back} type="button">
            <FormattedMessage id="button.back" defaultMessage="Back" />
          </Button>
        )}
        <p className="panel panel-primary">Menu does not exists</p>
      </div>
    );
  }

  const filteredRows = menu.rows.filter(r => r.visible && !r.hideCloud && !r.hideNonPro);

  return (
    <div className="device-menu-row">
      <div className="menu-heading">
        <h3>
          {menu.number === '0' ? '' : menu.number} {menu.name.text}
        </h3>
        {menu && menu.help && menu.help.text.length > 0 && (
          <Button
            className="button--default button--bottom-spacing"
            onClick={() => setShowHelpModal(!showHelpModal)}
            type="button"
            disabled={loadingDeviceSubMenu}
          >
            <FormattedMessage id="menu.systems.help" defaultMessage="Help" />
          </Button>
        )}
      </div>
      {loadingDeviceSubMenu ? (
        <Spinner dark />
      ) : (
        <ul className="list-group">
          {filteredRows.map((menuItem, i) => (
            <li key={`menuItem_${device.id}_#${i}`} className="list-group-item">
              <DeviceMenuItem
                accordionId={i}
                menuItem={menuItem}
                processEvent={processEvent}
                changeSetting={changeSetting}
                followLink={followLink}
                isManageEnabled={isManageEnabled}
                premiumFeatureType={premiumFeatureType}
                isDemoUser={userInfo && userInfo.isDemo}
              />
            </li>
          ))}
        </ul>
      )}
      {menuLevelsHistory.length > 1 && (
        <Button
          className="button--default button--bottom-spacing"
          onClick={back}
          type="button"
          disabled={loadingDeviceSubMenu}
        >
          <FormattedMessage id="button.back" defaultMessage="Back" />
        </Button>
      )}
      <DeviceMenuHelpModal
        menu={menu}
        showHelpModal={showHelpModal}
        hideHelpModal={() => setShowHelpModal(!showHelpModal)}
      />
    </div>
  );
};

DeviceMenu.propTypes = {
  device: PropTypes.object.isRequired,
  deviceMenu: PropTypes.object.isRequired,
  getDeviceMenu: PropTypes.func.isRequired,
  invokeDeviceEvent: PropTypes.func.isRequired,
  editMenuRawSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  userInfo: PropTypes.object,
  premiumFeatureType: PropTypes.string,
};

export default connect(
  ({ deviceMenu, app: { userInfo } }) => ({
    deviceMenu,
    userInfo,
  }),
  {
    getDeviceMenu,
    invokeDeviceEvent,
    editMenuRawSetting,
  },
)(DeviceMenu);
